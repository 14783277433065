import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Anchor from "@ui/anchor";
import Text from "@ui/text";
import Heading from "@ui/heading";
import SectionTitle from "@ui/section-title";
import Image from "@ui/image";

import {
  LocalizeWrapper,
  LocalizeInner,
  LocalizeContentbox,
  LocalizeContentboxInner,
  LocalizeIconwrap,
  // LocalizeTextwrap,
} from "./style";

const LocalizeGrid = ({ data }) => {
  return (
    <LocalizeWrapper id={data.section}>
      <LocalizeInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                title={data.section_title?.title}
                subtitle={data.section_title?.subtitle}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <LocalizeContentbox>
                {data &&
                  data.items.map((el, index) => (
                      <Anchor key={index} path={el.path} target="_blank">
                        <LocalizeContentboxInner>
                          <LocalizeIconwrap className="flipFront">
                            <Image
                              src={el.images[0].src}
                              alt={el.images[0]?.alt || "Icon"}
                            />
                            <Heading as="h4">{el.title}</Heading>
                            <Heading as="h6">{el.subject}</Heading>
                            <Text>{el.description}</Text>
                          </LocalizeIconwrap>
                        </LocalizeContentboxInner>
                      </Anchor>
                  ))}
              </LocalizeContentbox>
            </Col>
          </Row>
        </Container>
      </LocalizeInner>
    </LocalizeWrapper>
  );
};
export default LocalizeGrid;
